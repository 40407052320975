
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .container {
   p:last-child {
      margin-bottom: 0;
   }
}

.title{
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
}
.tip{
  font-size: 12px;
  color: #999999;
}
.warning{
  color: #FF0000;
}
    