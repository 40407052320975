/*
定义左侧菜单的变量
*/

/*
  下列变量为快捷变量 可直接定义多个颜色
*/
@lightBgColor: #fff; // 明亮模式的背景色
@lightTextColor: #1E304A; // 明亮模式的文字颜色
@lightSelectedBgColor: #EDF1F7; // 明亮模式的点击或选中的背景色
@lightHoverBgColor: #f3f3f3; // 明亮模式的hover的背景色
@lightTitleColor: rgba(0, 0, 0, 0.85); // 明亮模式的标题颜色

@darkTitleColor: #fff; // 黑暗模式的标题颜色
@darkSelectedTextColor: #fff; // 黑暗模式的选中字体颜色
@darkArrowColor: rgba(255, 255, 255, 0.48); //黑暗模式的icon或箭头色
@darkHoverBgColor: rgba(255, 255, 255, 0.08); // 黑暗模式的hover或选中的背景色
@darkActiveBgColor: rgba(255, 255, 255, 0.04); // 黑暗模式的点击的背景色

/*
  下列变量为详细的自定义变量 按需选择 不设置则使用系统默认值
*/
:root {
  // 颜色
  --xy-custom-sidebar-bg-color: @lightBgColor; // 背景
  --xy-custom-sidebar-sub-bg-color: @lightBgColor; // 子菜单背景色
  --xy-custom-sidebar-text-color: @lightTextColor; // 字体颜色
  --xy-custom-sidebar-icon-color:  @lightTextColor; // icon颜色
  --xy-custom-sidebar-arrow-color: #4A5970; // 菜单项箭头颜色
  --xy-custom-sidebar-hover-bg-color: @lightHoverBgColor; // 菜单项hover背景颜色
  --xy-custom-sidebar-hover-text-color: @lightTextColor; // 菜单项hover字体颜色
  --xy-custom-sidebar-active-bg-color: @lightSelectedBgColor; // 菜单项点击背景颜色
  --xy-custom-sidebar-active-text-color: @lightTextColor; // 菜单项点击字体颜色
  --xy-custom-sidebar-selected-bg-color: @lightSelectedBgColor; //没有子菜单项选中背景颜色
  --xy-custom-sidebar-selected-text-color: @lightTextColor; // 菜单项选中字体颜色
  --xy-custom-sidebar-selected-text-font-weight: bold; // 菜单项选中字体粗细
  --xy-custom-sidebar-selected-icon-color: var(--wuji-primary-color); // 选中 icon颜色
  --xy-custom-sidebar-selected-parent-bg-color: transparent; // 子菜单项被选中的父菜单项背景颜色
  --xy-custom-sidebar-selected-parent-text-color: @lightTextColor; // 子菜单项被选中的父菜单项字体颜色
  --xy-custom-sidebar-selected-parent-icon-color: var(--wuji-primary-color); // 子菜单项被选中的父菜单项icon颜色
  --xy-custom-sidebar-title-border-bottom: 1px solid #eee; // 应用标题整体下边框
  --xy-custom-sidebar-title-color: #000000d9; // 应用标题字体颜色
  --xy-custom-sidebar-menu-title-logo-color: @lightBgColor; // 菜单标题logo颜色
  --xy-custom-sidebar-menu-title-logo-bg-color: var(--wuji-primary-color); // 菜单标题logo背景色
  --xy-custom-sidebar-menu-title-text-color: @lightTitleColor; // 菜单标题颜色
  --xy-custom-sidebar-scrollbar-thumb-bg-color: rgba(0, 0, 0, 0.12); // 滚动条颜色
  --xy-custom-sidebar-scrollbar-thumb-hover-bg-color: rgba(0, 0, 0, 0.2); // 滚动条hover颜色

  // 布局
  --xy-custom-sidebar-padding: 16px 0 0 0; // 左侧菜单整体padding
  --xy-custom-sidebar-sub-menu-bottom-margin: 8px; // 打开的子菜单下margin
  --xy-custom-sidebar-menu-item-height: 40px; //菜单项高度
  --xy-custom-sidebar-menu-item-width: calc(100% - 16px); //菜单项宽度 需要和--xy-custom-sidebar-menu-item-margin配合使用
  --xy-custom-sidebar-menu-open-item-margin: 0 8px 4px 8px; // 打开的菜单项margin
  --xy-custom-sidebar-menu-item-margin: 0 8px 8px 8px; // 未打开或无子菜单菜单项margin
  --xy-custom-sidebar-menu-item-border-radius: 8px; // 菜单项圆角
  --xy-custom-sidebar-menu-item-right-padding-arrow: 48px; // 带箭头菜单项右侧padding
  --xy-custom-sidebar-menu-item-right-padding: 16px; // 菜单项右侧padding
  --xy-custom-sidebar-font-size-first: 14px; // 一级菜单项字体大小
  --xy-custom-sidebar-font-size-second: 14px; // 二级及以下菜单项字体大小
  --xy-custom-sidebar-font-size-icon: 16px; // 菜单项icon大小
  --xy-custom-sidebar-menu-item-left-padding-first: 16px; // 一级菜单左缩进padding
  --xy-custom-sidebar-menu-item-left-padding-second: 44px; // 二级菜单左缩进padding
  --xy-custom-sidebar-menu-item-left-padding-third: 66px; // 三级菜单左缩进padding
  --xy-custom-sidebar-menu-item-left-padding-forth: 88px; // 四级菜单左缩进padding
  --xy-custom-sidebar-title-height: 56px; // 应用标题整体高度
  --xy-custom-sidebar-title-padding: 0 16px; // 应用标题整体padding
  --xy-custom-sidebar-title-margin: 0 8px; // 应用标题整体margin
  --xy-custom-sidebar-title-gap: 8px; // logo和应用标题间隔
  --xy-custom-sidebar-title-logo-width: 40px; // logo宽度
  --xy-custom-sidebar-title-logo-height: 40px; // logo高度
  --xy-custom-sidebar-title-font-size: 20px; // 应用标题字体大小
  --xy-custom-sidebar-menu-title-margin: 8px 8px 8px; // 菜单标题整体margin
  --xy-custom-sidebar-menu-title-padding: 0 16px; // 菜单标题整体padding
  --xy-custom-sidebar-menu-title-logo-size: 24px; // 菜单标题logo 宽高
  --xy-custom-sidebar-menu-title-logo-font-size: 24px; // 菜单标题logo font-size
  --xy-custom-sidebar-menu-title-text-font-size: 16px; // 菜单标题字体大小
  --xy-custom-sidebar-menu-title-gap: 8px; // logo和菜单标题间隔
  --xy-custom-sidebar-menu-search-padding: 0 8px 8px 8px; // 菜单搜索框padding

  // 深色模式
  .dark {
    --xy-custom-sidebar-bg-color: var(--wuji-primary-bg-color-dark); // 背景色
    --xy-custom-sidebar-sub-bg-color: var(--wuji-primary-bg-color-dark); // 子菜单背景色
    --xy-custom-sidebar-text-color: var(--wuji-text-color-dark); // 字体颜色
    --xy-custom-sidebar-icon-color: @darkArrowColor; // icon颜色
    --xy-custom-sidebar-arrow-color: @darkArrowColor; // 菜单项箭头颜色
    --xy-custom-sidebar-hover-bg-color: @darkHoverBgColor; // 菜单项hover背景颜色
    --xy-custom-sidebar-hover-text-color: @darkSelectedTextColor; // 菜单项hover字体颜色
    --xy-custom-sidebar-active-bg-color: @darkActiveBgColor; // 菜单项点击背景颜色
    --xy-custom-sidebar-active-text-color: @darkSelectedTextColor; // 菜单项点击字体颜色
    --xy-custom-sidebar-selected-bg-color: @darkHoverBgColor; //没有子菜单项选中背景颜色
    --xy-custom-sidebar-selected-text-color: @darkSelectedTextColor; // 菜单项选中字体颜色
    --xy-custom-sidebar-selected-text-font-weight: bold; // 菜单项选中字体粗细
    --xy-custom-sidebar-selected-icon-color: @darkSelectedTextColor; // 选中 icon颜色
    --xy-custom-sidebar-selected-parent-bg-color: transparent; // 子菜单项被选中的父菜单项背景颜色
    --xy-custom-sidebar-selected-parent-text-color: @darkSelectedTextColor; // 子菜单项被选中的父菜单项字体颜色
    --xy-custom-sidebar-selected-parent-icon-color: @darkSelectedTextColor; // 子菜单项被选中的父菜单项icon颜色
    --xy-custom-sidebar-title-border-bottom: 1px solid var(--wuji-text-color); // 应用标题整体下边框
    --xy-custom-sidebar-title-color: @darkTitleColor; // 应用标题字体颜色
    --xy-custom-sidebar-menu-title-logo-color: @darkTitleColor; // 菜单标题logo颜色
    --xy-custom-sidebar-menu-title-logo-bg-color: @darkSelectedTextColor; // 菜单标题logo背景色
    --xy-custom-sidebar-menu-title-text-color: @darkTitleColor; // 菜单标题颜色
    --xy-custom-sidebar-scrollbar-thumb-bg-color: rgba(255, 255, 255, 0.2); // 滚动条颜色
    --xy-custom-sidebar-scrollbar-thumb-hover-bg-color: rgba(255, 255, 255, 0.4); // 滚动条hover颜色
  }
}
