
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    






















































































































































.diff_header {
  background: #fff;
  display: flex;
  justify-content: space-around;
  & > div {
    text-align: center;
    line-height: 27px;
  }
}

    