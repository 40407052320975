
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    








































































::v-deep .cherry-previewer{
  padding: 10px;
}
::v-deep .ant-modal-body{
  max-height: 500px;
  overflow: auto;
}


    