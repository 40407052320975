
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .layout {
  padding: 30px;
  overflow: auto;

  .regMode{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  .approveCard{
      display: flex;
      flex-direction: column;
      gap: 8px;
      &>div{
        display: flex;
        align-items: center;
      }
  }
}
.actionGroup {
  display: flex;
  gap: 8px;
}
.pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.table{
  width: 100%;
}


.longTextPrePopup {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 10em;
  max-width: 600px;
  padding: 8px 12px;
}

.longTextPre {
  overflow: hidden;
  max-width: 400px;
  height: 5em;
  font-size: 75%;

  &:hover {
    outline: 1px solid currentColor;
  }
}

    