
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
















































































































#xy-client-hmr-page-list{
  .page-selection {
    width: 100px;
  }
  .debug-tips {
    margin-right: 5px;
    margin-left: 10px;
    display: inline;
    font-size: 14px;
    color: var(--wuji-primary-color);
  }
}

    