
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    































.home {
  padding-top: 16px;
  padding-left: 24px;
  &-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-icon {
    width: 16px;
    height: 16px;
  }
}

    