
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





























































































































































.custom-wuji-layout {
  .w-sys-main-pagelet {
    min-height: 100%;
  }
}

    