
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
























































.xy-pageLoadingIndicator {
  color: var(--wuji-primary-color);
  position: relative;  // 兼容
  position: sticky;
  top: 0;
}

$barSize: 2px;
.xy-pageLoadingIndicator-fill {
  position: absolute;
  left: 0;
  top: -$barSize / 2;
  height: $barSize;
  z-index: 1000;
  overflow: hidden;

  background-color: currentColor;
  box-shadow: 0 0 2px currentColor;

  transition: all 1s;
  opacity: 1;

  pointer-events: none;

  &.isStatus0 {
    transition-duration: 0s;
    width: 0;
  }

  &.isStatus1 {
    transition-duration: 2s;
    width: 85%;
  }

  &.isStatus2 {
    transition-duration: 0.2s;
    width: 100%;
    opacity: 0;
  }
}

.xy-pageLoadingIndicator-highlightAnimation {
  position: absolute;
  left: -30%;
  top: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(to right, transparent, #fff);
  opacity: 0.8;
  animation: xy-pageLoadingIndicator-highlightAnimation 2s ease-out infinite;
}

@keyframes xy-pageLoadingIndicator-highlightAnimation {
  0% {
    left: -30%;
  }
  100% {
    left: 130%;
  }
}

    