
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


































































.flex-container {
  display: flex;
  align-items: center;
}
.mini-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}

    