
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

























































































section + section {
  margin-top: 12px;
}

.label {
  color: #333;
  font-size: 14px;
  line-height: 28px;
}

    