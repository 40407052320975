
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .normal_modal {
    :global(.ant-modal), :global(.ant-modal-content) {
        width: 900px;
        height: 650px;
    }
    :global(.ant-modal-body) {
      display: flex;
      flex-direction: column;
      height: calc(100% - 55px);
    }
}
.full_screen_modal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    :global(.ant-modal), :global(.ant-modal-content) {
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0;
       }
    :global(.ant-modal-body) {
      height: calc(100vh - 110px);
      display: flex;
      flex-direction: column;
    }
}

.conflict_modal_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
}

.code_merge_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.summary_section {
    display: flex;
    justify-content: space-between;
}

.conflict_header {
    font-weight: 500;
    text-align: left;
    color: rgba(0,0,0,.85);
    background: #fafafa;
    padding: 16px;
    overflow-wrap: break-word;
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.conflict_list {
    & > div {
        display: grid;
        flex-direction: row;
        grid-template-columns: 120px repeat(2, minmax(88px, 1fr));
        grid-row-gap: 12px;
        min-height: 30px;
    }
}
.conflict_group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    & > div {
        border-left: 1px solid #e8e8e8;
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0 15px;
        color: rgba(0,0,0,.65);
        line-height: 30px;
        background: #fff;
        border: 1px solid #e8e8e8;
        border-top: 1.02px solid #e8e8e8;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    & > .label {
      color: rgba(0,0,0,.85);
      background: #fafafa;
    }
    & > .option {
        cursor: pointer;
        &:hover {
            border-color: #047ff2;
        }
    }
    & > .checked {
        color: #fff;
        background: #1890ff;
    }
}
.patch {
    display: flex;
    justify-content: space-around;
}
.conflict_summary {
    font-size: 16px;
    margin-bottom: 16px;
}

.conflict_current_highlight {
    background-color: #fff8c5;
    z-index: -1;
}

.conflict_incoming_highlight {
    background-color:  #c4ffc9;
    z-index: -1;
}

.navbar {
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f6f8fa;
    border:  1px solid #d0d7de;
    .conflict_message {
        color: #cf222e;
        font-weight: bold;
        margin-right: 12px;
    }
}

.conflict_content {
    flex: 1;
    border: 1px solid #d0d7de;
    overflow-y: auto;
}

//-------------------- ConflitMergeModal --------------------
.editor_container_wrap {
    width: 100%;
    height: 100%;
}

:global(.conflict_merge_modal) {
    :global(.ant-drawer-title) {
        color: #fff
    }
    :global(.ant-drawer-header) {
        border-bottom: 1px solid #000000;
    }
    :global(.ant-drawer-body) {
        background-color: #fff;
        padding: 0;
    }
}
    