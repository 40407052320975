
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





























































































































































































































































































































































































































































































































































































































$menuItemHeight: var(--xy-custom-sidebar-menu-item-height);

.xy-sidebar {
  &.runtime {
    border-right: 1px solid #eee;
  }

  &.miniProgram {
    border-right: 0;
  }

  // from ui-core/src/comps/Aside.vue
  .xy-sidebar-resizer{
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    margin: 0 -2px;
    cursor: ew-resize;
    z-index: 1;
    right:0;
    &:hover {
      width: 6px;
      margin: 0 -3px;
      background: rgba(#fff, 0.06);
    }
  }

  .drawer-mask{
    position: fixed;
    top: var(--xy-header-height);
    width: 0;
    height: 0;
    transition: background 0.3s ,left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0s 0.3s , height 0s 0.3s;
    z-index: 1;
    &-opened{
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      transition: background 0.3s ,left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0s 0s , height 0s 0s;
    }
  }

  .sidebar-identify {
    padding: 16px 0;
    cursor: pointer;
    text-align: center;
    color: rgba(0, 0, 0, 0.48);

    &.dark {
      color: rgba(255, 255, 255, 0.48);
    }
  }
}

#xy-runtime-sidebar.xy-default-sidebar.ant-layout-sider {
  background-color: var(--xy-custom-sidebar-bg-color);
}

.xy-default-sidebar {
  padding: var(--xy-custom-sidebar-padding);
  &.collapsed{
    margin-right: 0;
  }

  .ant-layout-sider-children {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-menu-inline{
    width: 100%;
  }

  // 隐藏antd默认的border
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
  }

  #xy-sidebar-title {
    height: var(--xy-custom-sidebar-title-height);
    display: flex;
    align-items: center;
    gap: var(--xy-custom-sidebar-title-gap);
    padding: var(--xy-custom-sidebar-title-padding);
    margin: var(--xy-custom-sidebar-title-margin);
    border-bottom: var(--xy-custom-sidebar-title-border-bottom);
    box-sizing: border-box;
    transition: padding 0.3s;
    &.collapsed{
      padding: 0;
    }

    .xy-sidebar-logo {
      width: var(--xy-custom-sidebar-title-logo-width);
      height: var(--xy-custom-sidebar-title-logo-height);
      overflow-x: hidden;
    }
    .xy-sidebar-text {
      font-size: var(--xy-custom-sidebar-title-font-size);
      color: var(--xy-custom-sidebar-title-color);
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #xy-sidebar-menu-title {
    margin: var(--xy-custom-sidebar-menu-title-margin);
    height: $menuItemHeight;
    display: flex;
    align-items: center;
    gap: var(--xy-custom-sidebar-menu-title-gap);
    padding: var(--xy-custom-sidebar-menu-title-padding);
    box-sizing: border-box;
    transition: padding 0.3s;
    &.collapsed{
      padding: 0;
    }

    .xy-sidebar-logo {
      width: var(--xy-custom-sidebar-menu-title-logo-size);
      height: var(--xy-custom-sidebar-menu-title-logo-size);
      background-color: var(--xy-custom-sidebar-menu-title-logo-bg-color);
      border-radius: 100%;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      justify-content: center;
      .logo-icon{
        font-size: var( --xy-custom-sidebar-menu-title-logo-font-size);
        color: var(--xy-custom-sidebar-menu-title-logo-color);
        width: max-content;
        height: max-content;
      }
    }
    .xy-sidebar-text {
      font-family: PingFangSC-Medium;
      font-size: var(--xy-custom-sidebar-menu-title-text-font-size);
      color: var(--xy-custom-sidebar-menu-title-text-color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #xy-sidebar-menu-search {
    padding: var(--xy-custom-sidebar-menu-search-padding);
  }

  #xy-sidebar-menu {
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 4px;
      border: 2px solid transparent;
      transition: background-color 0.2s;
    }

    &:focus-within,
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--xy-custom-sidebar-scrollbar-thumb-bg-color);

        &:hover {
          background-color: var(--xy-custom-sidebar-scrollbar-thumb-hover-bg-color);
        }
      }
    }

    &.ant-menu-root {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &.hidden {
      display: none;
    }

    .first-menu-item.ant-menu-item,
    .first-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-first) !important;
      font-size: var(--xy-custom-sidebar-font-size-first);
    }

    .second-menu-item.ant-menu-item,
    .second-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-second) !important;
    }

    .third-menu-item.ant-menu-item,
    .third-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-third) !important;
    }

    .forth-menu-item.ant-menu-item {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-forth) !important;
    }

    .ant-menu-item, .ant-menu-submenu {
      padding-bottom: 0;
      .icon-placeholder {
        margin-left: -10px;
      }
      i.anticon {
        flex-shrink: 0;
      }
      .ellipsis {
        display: inline-block;
        text-overflow: ellipsis; // 在多行文本的情况下，用...隐藏超出范围的文本
        word-break: break-all;
        overflow: hidden;
      }
    }

    .ant-menu-submenu-open {
      .ant-menu {
        margin-bottom: var(--xy-custom-sidebar-sub-menu-bottom-margin);
      }
      .ant-menu-submenu-title,.ant-menu-item {
        margin: var(--xy-custom-sidebar-menu-open-item-margin);
      }
    }

    .ant-menu-item, .ant-menu-submenu-title {
      height: $menuItemHeight;
      line-height: $menuItemHeight;
      box-sizing: border-box;
      margin: var(--xy-custom-sidebar-menu-item-margin);
      padding-bottom: 0;
    }

    .ant-menu-item {
      padding-right: var(--xy-custom-sidebar-menu-item-right-padding);
    }
    .ant-menu-submenu-title {
      padding-right: var(--xy-custom-sidebar-menu-item-right-padding-arrow);
    }

    // 菜单收起的情况
    .ant-menu-inline-collapsed {
      .ant-menu-item, .ant-menu-submenu-title {
        display: flex;
        justify-content: center;
        .anticon {
          margin-right: 0px;
          line-height: 16px;
        }
      }
      .ant-menu-submenu-selected {
        .anticon {
          color: var(--wuji-primary-color);
        }
      }
    }

    .ant-menu-item, .ant-menu-submenu-title {
      width: var(--xy-custom-sidebar-menu-item-width);
      .anticon {
        margin-right: 8px;
        font-size: var(--xy-custom-sidebar-font-size-icon);
        width: var(--xy-custom-sidebar-font-size-icon);
        height: var(--xy-custom-sidebar-font-size-icon);
      }
      & > span {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
      font-size: var(--xy-custom-sidebar-font-size-second);
    }

    // 去除选中态右侧的边框
    .ant-menu-item::after {
      opacity: 0;
    }
  }
}


.header-hidden {
  display: none;
}


    