
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


































































































































































































































::v-deep .ant-drawer-body{
  padding: 0;
  overflow: hidden;
  height: calc(100% - 55px);
  .header{
    display: flex;
    justify-content: space-between;
    line-height: 100%;
    .title{
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ant-tabs-bar{
    margin: 0;
    .ant-tabs-tab{
      margin: 0;
      min-width: 88px;
      text-align: center;
    }
  }

  .anncmnt-container {
    overflow: auto;
    height: calc(100% - 45px);
    .anncmnt{
      padding: 24px 24px 12px 24px;
      border: 1px solid #e8e8e85e;
      &:hover {
            background-color: rgba(182, 182, 182, 0.15);
            cursor: pointer;
      }
    }
    .editor-demo,.cherry{
      min-height: 0px;
    }
    .content {
      max-height: 150px;
      overflow: hidden;
    }
    .cherry.cherry--no-toolbar .cherry-previewer{
      padding: 12px 0px;
      p{
        margin: 0;
      }
    }
  }


}

    