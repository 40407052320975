
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

































































































.marquee{
  flex: 1;
  margin: 0 15px;
  height: 100%;
  overflow: hidden;
  .marquee-text-wrap{
    width: 100%;
    .marquee-text-content{
      height: 100%;
      width: max-content;
    }
  }
  .anncmnt-span{
    padding-right: 6px;
    cursor: pointer;
  }
}

    