
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    



































































































































































































































































































































































































































































































































































































:root {
  --xy-header-height: 56px;
}

/*
  导航下拉框的统一配置 包括环境选择下拉框、 公共查询参数、 右侧快捷入口下拉框
*/
@import './popover-styles.scss';

    