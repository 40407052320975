
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
















































































































































































.personal-info-panel{
  width: 200px;
  background: white;
  padding: 0px 0px 0px 0px ;

  .personal-info-panel-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0px 12px 6px 12px;
    padding-top: 12px;
  }

  .personal-info-panel-content{
    margin-top: 10px;
  }

  .info-title{
    font-size: 14px;
    font-weight: normal;
    color: #5c5c5c;
    margin-bottom: 5px;
  }

  .key{
    display: flex;
    justify-content: space-between;
    line-height: 15px;
    &-content {
      margin-bottom: 0;
      color: #5c5c5c;
    }
  }

  .personal-info-setting {
    padding: 8px 12px;
    font-weight: normal;
    color: #5c5c5c;
    &:hover {
      cursor: pointer;
      background-color: rgba(black, 0.05);
    }
  }
}

.personal-info-panel.light {
  .nav-link-item-logout a {
    color: var(--wuji-text-color);
  }
  .pending-icon:hover,
  .nav-link-item:hover,
  .nav-link-item:hover a {
    color: var(--wuji-text-color);
  }
  .pending-icon:active,
  .nav-link-item:active,
  .nav-link-item:active a {
    background: rgba(0, 0, 0, 0.04);
  }
}

// 头像下拉框内容
.personal-info-panel.dark {
  color: var(--wuji-text-color-dark);
  background:var(--wuji-primary-bg-color-dark);

  .pending-icon,
  .nav-link-item,
  .nav-link-item a {
    color: var(--wuji-text-color-dark);
  }

  .pending-icon:hover,
  .nav-link-item:hover,
  .nav-link-item:hover a {
    color: var(--wuji-text-color-dark);
    background: rgba(255, 255, 255, 0.08);
  }
  .pending-icon:active,
  .nav-link-item:active,
  .nav-link-item:active a {
    background: rgba(255, 255, 255, 0.04);
  }

  .nav-link-item-logout {
    border-color:rgba(255, 255, 255, 0.04);
  }
}

    