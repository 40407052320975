
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    






















































.xy-inner-pagelet-selector {
  width: 100%;
  .xy-inner-pagelet-selector-select {
    width: 100%;
  }
}

    