
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .spin {
    position: absolute; 
    width: 100%; 
    height: 100vh; 
    top: 0; 
    left: 0; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
    