
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    











































































































































.tag-view {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.tag-container {
  flex: 1;
  white-space: nowrap;
  overflow: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.tag-link {
  cursor: pointer;
  padding: 4px 8px;
  background: white;
  border-color: #e5e5e5;

  &-active {
    color: var(--wuji-primary-color);
  }
}

.tag-setting {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  padding-left: 12px;

  &:hover {
    .tag-setting-content {
      display: block;
    }
  }

  .tag-setting-content {
    display: none;
    margin-left: 16px;
  }
}

    