
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    



























































$collapse-trigger-width: 16px;
.collapse-trigger{
  position: absolute;
  width: $collapse-trigger-width;
  height: 100%;
  top: 0;
  right: -16px;
  transform: translateX(1px);

  .collapse-trigger-btn{
    width: $collapse-trigger-width;
    height: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    cursor: pointer;
    &:after{
      content:'';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    &:hover{
      .collapse-btn-bg {
        fill: var(--wuji-primary-color);
        fill-opacity: 1;
      }
      .trigger-icon-left{
        color: #fff;
      }
    }
    &.collapsed{
      .collapse-btn-bg{
        fill: var(--wuji-primary-color);
        fill-opacity: 1;
      }
    }
    .trigger-icon{
      width:100%;
      height:100%;
      position: absolute;
      top: 21px;
    }
    .trigger-icon-right{
      color: #fff;
    }
  }
}

    