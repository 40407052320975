
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

















































































































































.wrapper {
  background-color: var(--xy-custom-sidebar-bg-color);
}

.menus {
  background-color: var(--xy-custom-sidebar-bg-color);
  color: var(--xy-custom-sidebar-text-color);
  &-tab {
    padding-left: 24px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-active {
    color: var(--xy-custom-sidebar-active-text-color) !important;
    background-color: transparent !important;
  }
}

.ant-menu {
  box-shadow: none !important;
}

::v-deep .menus-wrapper {
  background-color: var(--xy-custom-sidebar-bg-color);
  .ant-menu {
    background-color: var(--xy-custom-sidebar-bg-color);
    color: var(--xy-custom-sidebar-text-color);
    .ant-menu-item:active {
      background-color: var(--xy-custom-sidebar-active-bg-color);
      color: var(--xy-custom-sidebar-active-text-color);
    }
    .ant-menu-item-selected {
      background-color: var(--xy-custom-sidebar-selected-bg-color);
      color: var(--xy-custom-sidebar-selected-text-color);
      font-weight: var(--xy-custom-sidebar-selected-text-font-weight);
    }
  }
}

    