
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .prompt {
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  :global(.ant-input) {
    color: #fff;
    border-radius: 4px;
    background-color: #37373d;
    border-color: #37373d;
    &:hover, &:focus, &:active {
      border-color: #37373d;
      box-shadow: none;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
    