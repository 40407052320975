
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
















































































































































.nav-user {
  margin: var(--xy-custom-nav-user-margin);
  line-height: 32px;
  img {
    display: var(--xy-custom-nav-user-avatar-display);
    width: var(--xy-custom-nav-user-avatar-size);
    height: var(--xy-custom-nav-user-avatar-size);
    margin-right:var(--xy-custom-nav-user-avatar-name-gap);
    border-radius: 100%;
  }
  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .nav-user-name {
      display: var(--xy-custom-nav-user-name-display);
      font-size: var(--xy-custom-nav-font-size);
      line-height: 22px;
    }
  }
  &_icon {
    margin-left: var(--xy-custom-nav-user-name-icon-gap);
    display: var(--xy-custom-nav-user-icon-display) !important;
    font-size: 10px;
  }
  &.dark {
    pointer-events: none;
    .nav-user_icon {
      display: none
    }
  }
}

#xy-runtime-layout .header,
#xy-preview-layout .header {
  .nav-user {
    &_button,&_icon {
      color: var(--xy-custom-nav-color);
    }
    &.dark {
      pointer-events: auto;
      .nav-user_icon {
        display: inline-block;
      }
    }
    &_button{
      &:hover,
      &:hover .nav-user_icon{
        color: var(--xy-custom-nav-hover-color);
      }
      &.ant-popover-open,
      &.ant-popover-open .nav-user_icon {
        color: var(--xy-custom-nav-active-color);
      }
    }
  }
}

    