
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .local-load-error-modal {
  .content-item {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
    &:last-child {
      border-bottom: 0;
    }
  }
  .console-error {
    background: #fef0f1;
    border: 1px solid #fed7d7;
    word-wrap: break-word;
    color: #fc201e;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 5px 0;
  }
  .content-item-icon {
    padding-right: 5px;
  }
  .highlight-font {
    color: #7F0020;
  }
  .contenet-item-solution {
    .pic {
      padding: 10px;
      border: 1px solid #aaa;
      border-radius: 5px;
      margin: 10px 0;
    }
  }
}

    