
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    











































.uicoreMountedOmniInput {
  padding-bottom: 0 !important;  // 佛了
  > .ant-row-flex > .wuji-component-label {
    display: none;

    + .ant-col-18 {
      width: 100%;
      float: none;
      flex-grow: 1;
    }
  }
}

    