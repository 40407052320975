
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .operationAndSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  flex-wrap: wrap;
}

.search {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
}

.bizSelect {
  min-width: 0;
  flex-basis: 180px;
  flex-shrink: 0;
}

.syncResultHeader {
  :global(.ant-collapse) {
    background-color: white;
    border: 0px;

    :global(.ant-collapse-item) {
      border: 0px;
    }
  }

}

.syncResultWrapper {
  :global(.ant-alert) {
    margin-bottom: 4px;
  }
  :global(.ant-alert-warning) {
    border: 0px;
  }
  :global(.ant-alert-success) {
    border: 0px;
  }
}

.generalWrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .searchTag {
    display: flex;
    align-items: center;
  }
}

.activeRow {
  background-color: var(--wuji-primary-bg-color);
}

.disabledRoleUserNameTag {
  opacity: 0.5;
  text-decoration: line-through;
  border-color: transparent;
}

.clickableRoleUserNameTag {
  cursor: pointer;
  opacity: 1;
}

.inputedRoleUserNameTag {
  outline: 2px solid var(--wuji-primary-color);
  outline-offset: -2px;
  opacity: 1;
}

    