
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .commit_drawer_container {
    transform: none !important;
    :global(.ant-drawer-wrapper-body) {
      display: flex;
      flex-direction: column;
    }
    :global(.ant-drawer-body) {
        background: #f2f4f5;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
     :global(.ant-drawer-header) {
        height: 55px;
      }
     :global(.ant-drawer-body) {
        flex:1;
        overflow: auto;
    }
}
.container {
    flex: 1;
    overflow: hidden;
    display: flex;
    overflow-y: auto;
}

.sidebar {
  background: #fff;
  box-shadow: 0px 12px 48px 0px rgba(0, 0, 0, 0.03),0px 9px 28px 0px rgba(0, 0, 0, 0.05),0px 6px 16px 0px rgba(0, 0, 0, 0.08);
}

.changes_list {
    overflow: hidden;
    flex: 1;
}

.commit_action {
    background: #fff;
    padding: 24px 12px 0;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e8e8e8;
    > button {
        margin-top: 12px;
    }
}

.json_view {
    flex: 1;
    border: 1px solid #e8e8e8;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: #fff;
    overflow: auto;
    & > details {
      padding: 12px;
      border-right: 1px solid #e8e8e8;
    }
}

.editor_diff_detail {
  flex: 1;
  border: 1px solid #e8e8e8;
}

.editor_wrap_left {
    flex: 0 1 200px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid  #e8e8e8;
    position: relative;
    overflow: visible auto;
    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      border-color: #1990ff;
      background-color: #1990ff;
    }
  }
  
  .editor_wrap_right {
    flex: 1;
  }
  
  .conflict_title {
    padding: 4px 2px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .conflict_title_icon {
    margin-right: 4px;
    &.collapse {
      transform: rotate(-90deg);
    }
  }
  
  .conflict_item {
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 4px 8px;
    margin-bottom: 2px;
    margin-left: 12px;
  }
  
  .conflict_item:hover {
    cursor: pointer;
    background-color: #eee;
  }
  
  .conflict_item.selected {
    background-color: #d9d9d9;
  }

  .editor_wrap_right {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  .merge_modal_radio_group {
    display: flex;
    justify-content: flex-end;
    background: #fff;
    padding: 8px 16px;
    border-left: 1px solid #e8e8e8;
  }

  .commit_action_buttons {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
  }

  .sidebarResizer {
    position: absolute;
    right: -3px;
    top: 0;
    bottom: 0;
    width: 3px;
    cursor: ew-resize;
    z-index: 1;
    &:hover {
      background-color: #d8d8d8;
    }
  }

  // ConflictView
 .conflict_view_container {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #e8e8e8;
 }

 .conflict_sidebar_list {
  padding: 4px;
  border-right: 1px solid #e8e8e8;
  background: #fff;
  max-width: 300px;
 }
 .conflict_sidebar_item {
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding:4px 0;

  .title {
    color: rgba(0,0,0,.65);
    transition: all .3s;
  }
  .desc {
    color: rgba(0,0,0,.45);
    font-size: 12px;
    width: 100%;
  }
 }

 .conflict_content {
    flex: 1;
 }

 .checkbox {
  margin-right: 4px;
 }
    