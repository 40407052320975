
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
































































































#xy-client-layout-nav-comps {
  display: flex;
  align-items: center;
  justify-content: center;
}

    