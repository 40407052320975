#xy-runtime-layout {
  width: 100%;
  position: relative;

  #xy-runtime-header {
    overflow: hidden;
    position: relative;
    z-index: 20;
    white-space: nowrap;
  }

  #xy-runtime-wrap {
    position: relative;
    display: flex;

    #xy-runtime-sidebar {
      flex-shrink: 0;
      position: sticky;
      top: 0;
      z-index: 2;

      &.miniProgram {
        position: fixed;
        top: var(--xy-header-height);
        height: 100%;
        z-index: 999;
      }
    }

    #xy-runtime-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .xy-runtime-main-pagelet {
        flex: 1;
      }

      #xy-runtime-footer {
        flex-shrink: 0;
      }

      .page-header {
        background: #fff;
        border-bottom: 1px solid #e8e8e8;
        z-index: 19;
        width: 100%;
      }
    }
  }

  .mock-user-warning {
    position: absolute;
    z-index: 1;
    width: 240px;
    right: 20px;
  }

  // 页头
  .affix-container,
  .affix-container .ant-affix {
    background-color: #fff;
  }

  /*****
    左侧菜单中的菜单组件
  ********/
  #xy-runtime-sidebar .ant-menu#xy-sidebar-menu {
    background-color: var(--xy-custom-sidebar-bg-color);

    .ant-menu-sub {
      background-color: var(--xy-custom-sidebar-sub-bg-color);
    }

    // 菜单阴影
    .ant-menu-inline.ant-menu-sub {
      box-shadow: none;
    }

    .ant-menu-item:not(.ant-menu-item-selected),
    .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
      color: var(--xy-custom-sidebar-text-color);

      .anticon {
        color: var(--xy-custom-sidebar-icon-color); // 菜单item icon颜色
      }

      &:hover {
        color: var(
          --xy-custom-sidebar-hover-text-color
        ); // 菜单item hover字体颜色
        background-color: var(--xy-custom-sidebar-hover-bg-color);
      }

      &:active {
        color: var(--xy-custom-sidebar-active-text-color);
        background-color: var(
          --xy-custom-sidebar-active-bg-color
        ); // 菜单item 点击背景色
      }
    }

    .ant-menu-item.ant-menu-item-selected {
      // 没有子菜单 被选中
      color: var(--xy-custom-sidebar-selected-text-color);
      background-color: var(--xy-custom-sidebar-selected-bg-color);
      font-weight: var(--xy-custom-sidebar-selected-text-font-weight);

      .anticon + span,
      &:hover {
        color: var(--xy-custom-sidebar-selected-text-color);
      }

      .anticon {
        // icon
        color: var(--xy-custom-sidebar-selected-icon-color);
      }
    }

    // 有子菜单 选中
    .ant-menu-submenu-selected > .ant-menu-submenu-title {
      color: var(--xy-custom-sidebar-selected-parent-text-color);
      background-color: var(--xy-custom-sidebar-selected-parent-bg-color);

      .anticon + span {
        color: var(--xy-custom-sidebar-selected-parent-text-color);
      }

      .anticon {
        // icon
        color: var(--xy-custom-sidebar-selected-parent-icon-color);
      }

      &:hover {
        color: var(--xy-custom-sidebar-selected-parent-text-color);
        background-color: var(--xy-custom-sidebar-hover-bg-color);
      }
    }

    // 菜单item背景圆角
    .ant-menu-item,
    .ant-menu-submenu-title {
      border-radius: var(--xy-custom-sidebar-menu-item-border-radius);
    }

    // 箭头色
    .ant-menu-submenu .ant-menu-submenu-title,
    .ant-menu-submenu .ant-menu-submenu-title:hover,
    .ant-menu-submenu .ant-menu-submenu-title:active {
      .ant-menu-submenu-arrow {
        opacity: 1;

        &::before,
        &::after {
          background: var(--xy-custom-sidebar-arrow-color);
        }
      }
    }
  }
}
