
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    









































































.nav-link-item {
  font-size: var(--xy-custom-nav-font-size);
  color: var(--xy-custom-nav-color);
  &:hover {
    color: var(--xy-custom-nav-hover-color);
    cursor: pointer;
  }
  &#{&}-active {
    color: var(--xy-custom-nav-active-color);
  }
  .nav-link-item-icon {
    width: var(--xy-custom-nav-link-icon-size);
    height: var(--xy-custom-nav-link-icon-size);
  }
}

    