
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    // 全局样式
$themeColor: $themeColor;

// 用于组件内部使用的一些动态css变量
:root {
  --wuji-primary-color: #{$themeColor};
  --wuji-theme-wujired: "#7F0020";
  --wuji-theme-tencentblue: "#0052D9";
  --wuji-primary-bg-color: #EDF1F7;
  --wuji-text-color: #5c5c5c;
  --wuji-primary-bg-color-dark: #1C222C;
  --wuji-text-color-dark: rgba(255, 255, 255, 0.68);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Microsoft YaHei";
}

micro-app-body .ant-drawer {
  position: absolute !important;

  .ant-drawer-content-wrapper {
    height: auto;
  }
}

.v-step {
  background: #616161 !important;

  .v-step__header,
  .v-step__arrow:before {
    background: #616161 !important;
  }
}

    