
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    




























































.pending-icon {
  color: rgba(0, 0, 0, 0.9);
  padding: 10px 12px;
  line-height: 1.5;
  display: block;
  &:hover {
    background: var(--wuji-primary-bg-color);
    cursor: pointer;
  }
}
.router-link-active {
  color: var(--wuji-primary-color);
}

    