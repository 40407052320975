/*
定义顶部导航的变量
*/

/*
  下列变量为快捷变量 可直接定义多个颜色
*/
@lightBgColor: #fff; // 明亮模式的背景色
@lightTextColor: #1E304A; // 明亮模式的文字颜色
@lightSelectedBgColor: #EDF1F7; // 明亮模式的点击或选中的背景色
@lightHoverBgColor: #f3f3f3; // 明亮模式的hover的背景色
@lightTitleColor: rgba(0, 0, 0, 0.85); // 明亮模式的标题颜色

@darkTitleColor: #fff; // 黑暗模式的标题颜色
@darkSelectedTextColor: #fff; // 黑暗模式的选中字体颜色
@darkArrowColor: rgba(255, 255, 255, 0.48); //黑暗模式的icon或箭头色
@darkHoverBgColor: rgba(255, 255, 255, 0.08); // 黑暗模式的hover或选中的背景色
@darkActiveBgColor: rgba(255, 255, 255, 0.04); // 黑暗模式的点击的背景色

/*
  下列变量为详细的自定义变量 按需选择 不设置则使用系统默认值
*/
:root {
  // 颜色
  --xy-custom-nav-bg-color: @lightBgColor; // 顶部导航背景色
  --xy-custom-nav-box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1); // 顶部导航阴影
  --xy-custom-nav-color: var(--wuji-text-color); // 字体颜色
  --xy-custom-nav-hover-color: var(--wuji-primary-color); // hover颜色
  --xy-custom-nav-active-color: var(--wuji-primary-color); // 激活颜色
  --xy-custom-nav-project-name-color: @lightTitleColor; // 顶部应用名颜色
  --xy-custom-nav-drop-pop-bg-color: @lightBgColor; // 顶部导航下拉框背景色
  --xy-custom-nav-drop-option-text-color: var(--wuji-text-color); // 顶部导航下拉菜单选项字体颜色
  --xy-custom-nav-drop-option-hover-bg-color: var(--wuji-primary-bg-color); // 顶部导航下拉菜单选项hover背景色
  --xy-custom-nav-drop-option-hover-text-color: var(--wuji-text-color); //顶部导航下拉菜单选项hover字体颜色
  --xy-custom-nav-drop-option-active-bg-color: @lightHoverBgColor; // 顶部导航下拉菜单选项点击背景色
  --xy-custom-nav-drop-option-active-text-color: var(--wuji-text-color); // 顶部导航下拉菜单选项点击字体颜色
  --xy-custom-nav-drop-option-selected-bg-color: var(--wuji-primary-bg-color); // 顶部导航下拉菜单选项选中背景色
  --xy-custom-nav-drop-option-selected-text-color: var(--wuji-text-color); // 顶部导航下拉菜单选项选中字体颜色
  --xy-custom-menu-pop-bg-color: @lightBgColor; // 顶部导航菜单下拉框背景色
  --xy-custom-menu-pop-arrow-color: #4A5970; // 顶部导航菜单下拉框箭头色
  --xy-custom-menu-pop-item-text-color: @lightTextColor; // 顶部导航菜单下拉框字体颜色
  --xy-custom-menu-pop-item-hover-bg-color: @lightHoverBgColor; // 顶部导航菜单下拉框hover背景色
  --xy-custom-menu-pop-item-hover-text-color: @lightTextColor; //顶部导航菜单下拉框hover字体颜色
  --xy-custom-menu-pop-item-active-bg-color: @lightSelectedBgColor; // 顶部导航菜单下拉框点击背景色
  --xy-custom-menu-pop-item-active-text-color: @lightTextColor; //顶部导航菜单下拉框点击字体颜色
  --xy-custom-menu-pop-item-selected-bg-color: @lightSelectedBgColor; //顶部导航菜单下拉框选中背景色
  --xy-custom-menu-pop-item-selected-text-color: @lightTextColor; //顶部导航菜单下拉框选中字体颜色

  // 布局
  --xy-custom-nav-padding: 0 20px 0 16px; // 顶部导航padding
  --xy-custom-nav-wuji-navigator-margin: 0 8px 0 0; // 应用快捷切换面板margin
  --xy-custom-nav-logo-container-padding: 0 24px 0 8px; // 顶部logo整体 padding，包括logo和应用名
  --xy-custom-nav-logo-container-gap: 8px; // 顶部logo和应用名间隔
  --xy-custom-nav-logo-max-height: 32px; // 顶部logo最大高度
  --xy-custom-nav-project-name-font-size: 20px; // 顶部应用名字体大小
  --xy-custom-nav-font-size: 14px; // 顶部导航字体大小，除应用名外
  --xy-custom-nav-env-select-margin: 0 80px 0 0; // 顶部导航环境选择margin
  --xy-custom-nav-widget-margin: 0 32px 0 0; // 顶部导航公共查询参数标题margin
  --xy-custom-nav-widget-icon-display: inline-block; // 顶部导航公共查询参数是否显示icon
  --xy-custom-nav-multi-menu-title-gap: 40px; // 顶部导航多菜单标题间隔
  --xy-custom-nav-link-gap: 32px; // 右侧快捷入口间隔
  --xy-custom-nav-link-icon-size: 14px; // 右侧快捷入口icon大小
  --xy-custom-nav-user-margin: 0 0 0 32px; // 用户整体margin
  --xy-custom-nav-user-avatar-display: inline-block; // 是否展示用户头像
  --xy-custom-nav-user-name-display: inline-block; // 是否展示用户名
  --xy-custom-nav-user-icon-display: inline-block; // 是否展示下拉icon
  --xy-custom-nav-user-avatar-name-gap: 8px; // 头像和用户名间隔
  --xy-custom-nav-user-name-icon-gap: 6px; // 用户名和下拉icon间隔
  --xy-custom-nav-user-avatar-size: 24px; // 头像大小
  --xy-custom-nav-user-pop-padding: 0; // 头像下拉框整体padding
  --xy-custom-nav-drop-pop-padding: 0; // 顶部导航下拉菜单下拉框padding 除了头像下拉框
  --xy-custom-nav-drop-pop-min-width: 80px; // 顶部导航下拉菜单下拉框最小宽度 除了环境选择
  --xy-custom-nav-drop-pop-max-height: 410px; // 顶部导航下拉菜单下拉框最大高度 除了环境选择和头像下拉
  --xy-custom-nav-drop-option-padding: 10px 12px; // 顶部导航下拉菜单选项padding
  --xy-custom-nav-drop-option-margin: 0; // 顶部导航下拉菜单选项margin
  --xy-custom-nav-drop-option-font-size: 14px; // 顶部导航下拉菜单选项字体大小
  --xy-custom-menu-pop-padding: 4px 8px; // 顶部导航菜单下拉框padding
  --xy-custom-menu-pop-min-width: 128px; // 顶部导航菜单下拉框最小宽度
  --xy-custom-menu-pop-max-width: 384px; // 顶部导航菜单下拉框最大宽度
  --xy-custom-menu-pop-item-parent-padding: 0 20px 0 12px; // 顶部导航菜单有子菜单的菜单项padding
  --xy-custom-menu-pop-item-padding: 0 48px 0 12px; // 顶部导航菜单没有子菜单的菜单项padding
  --xy-custom-menu-pop-item-border-radius: 8px; // 顶部导航菜单菜单项圆角
  --xy-custom-menu-pop-item-height: 40px; // 顶部导航菜单菜单项高度
  --xy-custom-menu-pop-item-margin: 4px 0; // 顶部导航菜单项margin
  --xy-custom-menu-pop-item-icon-size: 16px; // 顶部导航菜单菜单项icon大小
  --xy-custom-menu-pop-item-gap: 10px; // 顶部导航菜单菜单项 icon和名称 间隔


  // 深色模式
  .dark {
    --xy-custom-nav-bg-color: var(--wuji-primary-bg-color-dark); // 背景色
    --xy-custom-nav-box-shadow: none; // 顶部导航阴影
    --xy-custom-nav-color: var(--wuji-text-color-dark); // 字体颜色
    --xy-custom-nav-hover-color: @darkSelectedTextColor; // hover颜色
    --xy-custom-nav-active-color: @darkSelectedTextColor; // 激活颜色
    --xy-custom-nav-project-name-color: @darkTitleColor; // 顶部应用名颜色
    --xy-custom-nav-drop-pop-bg-color: var(--wuji-primary-bg-color-dark); // 顶部导航下拉菜单下拉框背景色
    --xy-custom-nav-drop-option-text-color: var(--wuji-text-color-dark); // 顶部导航下拉菜单选项字体颜色
    --xy-custom-nav-drop-option-hover-bg-color: @darkHoverBgColor; // 顶部导航下拉菜单选项hover背景色
    --xy-custom-nav-drop-option-hover-text-color: var(--wuji-text-color-dark); //顶部导航下拉菜单选项hover字体颜色
    --xy-custom-nav-drop-option-active-bg-color: @darkActiveBgColor; // 顶部导航下拉菜单选项点击背景色
    --xy-custom-nav-drop-option-active-text-color: var(--wuji-text-color-dark); // 顶部导航下拉菜单选项点击字体颜色
    --xy-custom-nav-drop-option-selected-bg-color: @darkHoverBgColor; // 顶部导航下拉菜单选项选中背景色
    --xy-custom-nav-drop-option-selected-text-color: var(--wuji-text-color-dark); // 顶部导航下拉菜单选项选中字体颜色
    --xy-custom-menu-pop-bg-color: var(--wuji-primary-bg-color-dark); // 顶部导航菜单下拉框背景色
    --xy-custom-menu-pop-arrow-color: @darkArrowColor; // 顶部导航菜单下拉框箭头色
    --xy-custom-menu-pop-item-text-color: var(--wuji-text-color-dark); // 顶部导航菜单下拉框字体颜色
    --xy-custom-menu-pop-item-hover-bg-color: @darkHoverBgColor; // 顶部导航菜单下拉框hover背景色
    --xy-custom-menu-pop-item-hover-text-color: @darkSelectedTextColor; //顶部导航菜单下拉框hover字体颜色
    --xy-custom-menu-pop-item-active-bg-color: @darkActiveBgColor; // 顶部导航菜单下拉框点击背景色
    --xy-custom-menu-pop-item-active-text-color: @darkSelectedTextColor; //顶部导航菜单下拉框点击字体颜色
    --xy-custom-menu-pop-item-selected-bg-color: @darkHoverBgColor; //顶部导航菜单下拉框选中背景色
    --xy-custom-menu-pop-item-selected-text-color: @darkSelectedTextColor; //顶部导航菜单下拉框选中字体颜色
  }
}
