
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

























































































































.drag-item {
  border-bottom: 1px solid #7F0020;
  cursor: move;
}
.opt-btn {
  margin-top: 5px;
}

    