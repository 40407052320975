
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


































































































































































































































.xy-modal-container-design-wrap {
  position: relative;
  background-color: rgba(0, 0, 0, 0.45);

  &[data-uc-design-mode] {
    min-height: 100%;
  }

  // 弹窗
  .xy-modal-container-design {
    .ant-modal-mask {
      position: absolute;
      z-index: 100;
    }
    .ant-modal-wrap {
      position: relative;
      z-index: 100;
    }

    .ant-modal {
      top: 0;
      padding: 12px 0;
      /* 防止设置超过视窗宽高 */
      max-width: 100%;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  // 抽屉
  .xy-drawer-container-design {
    position: absolute;
    .ant-drawer-content-wrapper {
      .ant-drawer-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .xy-modal-container-title,
        .xy-modal-container-title-default {
          flex-shrink: 0;
        }
        .xy-modal-container-content {
          flex: 1;
          overflow: auto;
        }
        .xy-modal-container-footer {
          flex-shrink: 0;
        }
      }
    }

    /* 防止设置超过视窗宽高 */
    &.ant-drawer-right,
    &.ant-drawer-left {
      .ant-drawer-content-wrapper {
        max-width: 100%;
      }
    }
    &.ant-drawer-bottom,
    &.ant-drawer-top {
      .ant-drawer-content-wrapper {
        max-height: 100%;
      }
    }
  }

  /* 弹窗相关变量 */
  --wuji-modal-header-padding: 16px 24px;
  --wuji-modal-header-border-bottom: 1px solid #e8e8e8;
  --wuji-modal-body-padding: 24px;
  --wuji-modal-footer-padding: 10px 16px;
  --wuji-modal-footer-border-top: 1px solid #e8e8e8;
  // 合并样式
  .xy-modal-container-design,
  .xy-drawer-container-design {
    // 自定义标题的样式
    .xy-modal-container-title {
      padding: var(--wuji-modal-header-padding);
      border-bottom: var(--wuji-modal-header-border-bottom);
      .xy-modal-container-title-placeholder {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        border: 1px dashed;
      }
    }
    // 默认标题的样式
    .xy-modal-container-title-default {
      padding: var(--wuji-modal-header-padding);
      border-bottom: var(--wuji-modal-header-border-bottom);
      .xy-modal-container-title-text {
        margin: 0;
        color: #161616;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        word-wrap: break-word;
      }
    }
    .xy-modal-container-content {
      padding: var(--wuji-modal-body-padding);
      overflow: auto;
      .xy-modal-container-content-placeholder {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        border: 1px dashed;
      }
    }

    .xy-modal-container-footer {
      padding: var(--wuji-modal-footer-padding);
      border-top: var(--wuji-modal-footer-border-top);
      .xy-modal-container-footer-placeholder {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        border: 1px dashed;
      }
    }
  }
}

    