
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


















































































































::v-deep .ant-form-item {
  margin-bottom: 0px;
  .ant-form-item-label {
    text-align: left;
  }
}

    