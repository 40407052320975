
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
























































































































































































.header {
  z-index: 1001;
  height: var(--xy-header-height);
  line-height: var(--xy-header-height);
  padding: 0px 4px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1);
  background: var(--xy-custom-nav-bg-color);
  color: var(--xy-custom-nav-color);
  .ant-menu {
    background: transparent;
  }
}

.env-select-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.vertical-center{
  display: flex;
  align-items: center
}

.icon-button{
  font-size: 20px;
  padding: 16px 12px;
}

::v-deep.mini-program-nav-drawer {
  .ant-drawer-body {
    padding: 12px;
  }
  .ant-drawer-content {
    background: var(--xy-custom-nav-bg-color);
    color: var(--xy-custom-nav-color);
  }
}

    