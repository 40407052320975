
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    










































































































































































































































































































































































































































































































































.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

.header-container {
  height: 56px;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
  border-left: 1px solid #F0F2F5;

  .title {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 20px;
      color: rgba(0,0,0,0.90);
      line-height: 28px;
      margin: 0;
      padding-left: 12px;
    }
  }
}

.search-container {
  background-color: #FFF;
  margin-bottom: 16px;
  padding: 24px;
}

.container {
  padding: 24px;
  border-radius: 4px;
  background-color: #FFF;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: 300px auto;
}

.content {
  margin-top: 16px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  vertical-align: middle;
}

.label-value {
  display: grid;
  grid-template-columns: 160px auto;
  gap: 8px;

  .label {
    justify-self: end;
  }
}

    