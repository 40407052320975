
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





























































































































































































































































































































































































































.pluginList {
  ::v-deep .ant-modal {
    padding: 0;
    .wrapper {
      position: relative;
      overflow: hidden;
      min-height: 433px;
    }
    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 12px;
      grid-column-gap: 12px;
      padding: 24px;

      .pluginItem {
        border-radius: 4px;
        border-color: #d0d7de;
        border-style: dotted;
        border-width: 1px;
        padding: 24px 24px 18px 24px;
        &.installed {
          border-style: solid;
        }

        .pluginTitle {
          display: flex;
          justify-content: space-between;
          .pluginTitleWrap {
            display: flex;
            align-items: center;
          }
          .pluginTitleText {
            font-size: 16px;
            font-weight: 600;
            max-width: 200px;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 8px;
            color: #24292f;
          }
          .pluginTitleIconWrap {
            height: 32px;
            width: 32px;
            border-radius: 16px;
            box-shadow: 0 3px 6px rgba(140,149,159,0.15);
            overflow: hidden;
            .pluginTitleIcon {
              width: 100%;
              height: 100%;
            }
          }

          .pluginAuthor{
            padding: 0;
            margin: 0;
            color: rgb(87, 96, 106);
            font-size: 12px;
            height: 18px;
            &:hover {
              border-bottom: 1px solid rgb(87, 96, 106, 0.8);
            }
          }

          .ant-tag {
            margin-right: 4px
          }
        }

        .pluginBody {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .pluginDescription {
            overflow: hidden;
            font-size: 12px;
            height: 72px;
            box-sizing: border-box;
            margin-top: 4px;
            margin-bottom: 8px;
            color: #57606a;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }

          .pluginBodyBtns {
            display: flex;
            justify-content: space-between;
            .ant-btn-link {
              padding: 0;
              color: #5c5c5c;
            }
            .disabled {
              color: rgba(#5c5c5c, 0.6)
            }
          }
        }
      }
    }
    .disabled {
      cursor: not-allowed;
    }
    .emptyList {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .ant-modal-body {
      padding: 0px;
      // max-height: 481px;
      max-height: calc(100vh - 120px);
      overflow: auto;
    }
  }
}

    