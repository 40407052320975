
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

























































.box1 {
  background-color: #fff;
  padding: 20px 10px;
  box-shadow: 0 2px 8px #0002, 0 8px 32px #0006;
  border-radius: 16px;
  position: relative;
}

$cancelBtnSize: 32px;

.cancelBtn {
  position: absolute;
  right: (-$cancelBtnSize / 2);
  top: (-$cancelBtnSize / 2);
  cursor: pointer;
  width: $cancelBtnSize;
  height: $cancelBtnSize;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #eee;
  border-radius: 100%;
  font-size: 16px;
  line-height: 1;

  &:hover {
    box-shadow: 0 0 1px 2px;
  }
}

.switchIframe {
  width: 300px;
  height: 318px;
  border: 0;
}

.globalMask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: #0003;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

    