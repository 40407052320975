
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    








































































































































































































































































































































































.w-sys-modal {
  /* 弹窗 */
  .ant-modal {
    padding-bottom: 0px;
    /* 防止设置超过视窗宽高 */
    max-width: 98vw;
    .ant-modal-body {
      padding: 0;
    }
  }

  /* 抽屉 */
  .ant-drawer-body {
    height: 100%;
    padding: 0px;
  }
  /* 防止设置超过视窗宽高 */
  &.ant-drawer-right,
  &.ant-drawer-left {
    .ant-drawer-content-wrapper {
      max-width: 100vw;
    }
  }
  &.ant-drawer-bottom,
  &.ant-drawer-top {
    .ant-drawer-content-wrapper {
      max-height: 100vh;
    }
  }
}
.w-sys-modal_none_pointer_events {
  pointer-events: none;
}

    