
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.w-sys-pagelet {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  .loading-icon {
    position: absolute;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%);
  }

  > ::v-deep .uc-renderer {
    flex-grow: 1;
  }
}
// 只有主页面才有默认背景色
.w-sys-main-pagelet.w-sys-content-pagelet {
  background: var(--wuji-primary-bg-color);
}

    