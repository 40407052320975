
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    






























.wf-wrapper {
  display: flex;
}

.aside {
  height: calc(100vh - var(--xy-header-height));
}

.main {
  width: 100%;
  overflow: auto;
  background: var(--wuji-primary-bg-color);
}

    