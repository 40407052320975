/*
  导航下拉框的统一配置 包括环境选择下拉框、 公共查询参数、 右侧快捷入口下拉框
*/
.nav-switch,
.nav-drop {

  &-popover {
    .ant-popover-inner-content {
      min-width: var(--xy-custom-nav-drop-pop-min-width);
      max-height: var(--xy-custom-nav-drop-pop-max-height);
    }
  }
}

.env-select,
.nav-switch,
.nav-drop {

  &-popover {
    .ant-popover-inner-content {
      padding: var(--xy-custom-nav-drop-pop-padding);
    }
  }
}

.env-select,
.nav-switch,
.nav-drop,
.runtime.nav-user {

  &-popover {

    // 选项公共属性
    .ant-popover-inner-content {
      overflow: auto;

      .env-select_options,
      .nav-switch_options,
      .nav-link-item {
        padding: var(--xy-custom-nav-drop-option-padding);
        margin: var(--xy-custom-nav-drop-option-margin);
        font-size: var(--xy-custom-nav-drop-option-font-size);
      }
    }
  }

  // 选项颜色
  &-popover .ant-popover-inner-content {
    color: var(--xy-custom-nav-drop-option-text-color);
    background-color: var(--xy-custom-nav-drop-pop-bg-color);

    .env-select_options,
    .nav-switch_options,
    .nav-link-item {
      color: var(--xy-custom-nav-drop-option-text-color);
      cursor: pointer;

      &:hover {
        color: var(--xy-custom-nav-drop-option-hover-text-color);
        background-color: var(--xy-custom-nav-drop-option-hover-bg-color);
      }

      &:active {
        color: var(--xy-custom-nav-drop-option-active-text-color);
        background-color: var(--xy-custom-nav-drop-option-active-bg-color);
      }

      &-active {
        color: var(--xy-custom-nav-drop-option-selected-text-color);
        background-color: var(--xy-custom-nav-drop-option-selected-bg-color);
      }
    }
  }

  // 下拉框箭头
  &-popover.ant-popover-placement-bottomRight,
  &-popover.ant-popover-placement-bottom {
    >.ant-popover-content>.ant-popover-arrow {
      border-top-color: var(--xy-custom-nav-drop-pop-bg-color);
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: var(--xy-custom-nav-drop-pop-bg-color);
    }
  }

  &-popover.ant-popover-placement-top,
  &-popover.ant-popover-placement-topRight {
    >.ant-popover-content>.ant-popover-arrow {
      border-right-color: var(--xy-custom-nav-drop-pop-bg-color);
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: var(--xy-custom-nav-drop-pop-bg-color);
    }
  }
}

// 待办 选中态设置跟随nav-link-item-active
.runtime.nav-user-popover .ant-popover-inner-content .pending-icon {
  color: var(--xy-custom-nav-drop-option-text-color);

  &:hover {
    color: var(--xy-custom-nav-drop-option-hover-text-color);
  }

  &:active {
    color: var(--xy-custom-nav-drop-option-active-text-color);
  }
}
